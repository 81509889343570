import { CircleFilled, CircleOutlined } from '@/components/core/Circle';
import theme from '@/utils/styles-variables';

const DetailsGraphicElements = () => (
  <>
    <CircleOutlined
      style={{ position: 'absolute', top: '-10px', left: '-176px' }}
      size="56px"
      color={theme.colors.primary.light}
    />

    <CircleFilled
      style={{ position: 'absolute', top: '40px', right: '-220px' }}
      size="35px"
      color={theme.colors.primary.default}
    />

    <CircleOutlined
      style={{ position: 'absolute', top: '90px', right: '-150px' }}
      size="103px"
      color={theme.colors.primary.light}
    />

    <CircleFilled
      style={{ position: 'absolute', top: '272px', left: '-456px' }}
      size="280px"
      color={theme.colors.neutral.default}
    />
  </>
);

export default DetailsGraphicElements;
