import { Container } from '@/components/core/flexbox';
import { Wrapper, InnerWrapper } from './ContactUsStyles';

const ContactUs = ({ content }) => {
  const { upperLabel, label, email } = content;

  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          <p>{upperLabel}</p>
          <h4>{label}</h4>
          <a href={`mailto:${email}`}>{email}</a>
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

export default ContactUs;
