import Link from 'next/link';
import styled from 'styled-components';
import ReadingTime from '@/components/ReadingTime';
import getImgSrcWorkaround from '@/utils/images';
import { Title } from '@/components/core';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: end;
  background-image: url(${(props) => props.image});
  background-size: cover;
  height: 416px;
  padding: 24px;
  user-select: text;
  margin-top: 32px;
  border-radius: 4px;
`;

const NextPostDetails = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  width: 341px;
  padding: 32px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 8px;
`;

const DateInfo = styled.div`
  display: inline-block;
  font-size: 14px;
  user-select: text;
  &:not(:first-child) {
    padding-left: 22px;
    position: relative;
    &:before {
      position: absolute;
      opacity: 0.3;
      content: '';
      top: 10px;
      left: 8px;
      height: 6px;
      width: 6px;
      background-color: ${(props) => props.theme.colors.grey.dark};
      border-radius: 50%;
    }
  }
`;

const DateLabel = styled.div`
  opacity: 0.7;
  user-select: text;
`;

const Category = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  user-select: text;
`;

const NextPostDesktop = ({ post, postType, hasReadingTime }) => {
  const { featuredImage, category, title, publishedOn, publishedAt, content, slug, wordCount } =
    post;
  return (
    <Link href={`/${postType.url}/${slug}`}>
      <Wrapper image={getImgSrcWorkaround(featuredImage?.url)}>
        <NextPostDetails>
          {category?.displayName && <Category>{category?.displayName}</Category>}
          <Title size="lg">{title}</Title>
          <div>
            <DateInfo>
              <DateLabel>
                {new Date(publishedOn || publishedAt).toLocaleDateString('en-US', {
                  dateStyle: 'long',
                })}
              </DateLabel>
            </DateInfo>
            {hasReadingTime && (
              <DateInfo>
                <ReadingTime content={content} hasReadingTime wordCount={wordCount} />
              </DateInfo>
            )}
          </div>
        </NextPostDetails>
      </Wrapper>
    </Link>
  );
};

export default NextPostDesktop;
