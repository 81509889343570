import styled from 'styled-components';
import Subscriber from '../Subscriber';
import { Container } from '@/components/core/flexbox';

export const SubscriberAll = styled(Subscriber)`
  margin: 0;
  padding: ${({ hasMore }) => (hasMore ? '136px 0 48px' : '72px 0 48px')};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: ${({ hasMore }) => (hasMore ? '104px 0 48px' : '55px 0 48px')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: ${({ hasMore }) => (hasMore ? '64px 0 0' : '36px 0 0')};

    ${Container} {
      border-radius: 0;
    }
  }
`;

export const CustomContainer = styled(Container)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0;
  }
`;

export const PostsWrapper = styled.div`
  padding-top: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0;
  }
  .load-more-wrapper {
    padding-top: 0;
  }
`;
