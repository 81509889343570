'use client';

import { useState, useCallback } from 'react';
import { BreadcrumbJsonLd } from 'next-seo';

import { Container } from '@/components/core/flexbox';

import Subtitle from '../Subtitle';
import PostsList from '../posts/PostsList';
import HighlightedPost from '../posts/Highlighted';
import { ContentWrapper, PageWrapper } from '../Blog';
import ListGraphicElements from '../graphic-elements/ListGraphicElements';
import { PostsWrapper, CustomContainer, SubscriberAll } from './styles';

import CTA from '@/components/core/CTA';

import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

import BlogService from '@/services/blog';
import { Layout, Title } from '@/components/core';

const ResourcesBlog = ({
  bannerInfo,
  content,
  highlightedPost,
  shared,
  postsLists,
  postType,
  hasReadingTime = true,
  pageLimit = 12,
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [posts, setPosts] = useState(postsLists);
  const [hasError, setHasError] = useState(false);
  const hasMore = posts.meta.pagination.page < posts.meta.pagination.pageCount;

  const hasShape = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_md}px)`);

  const fetchData = useCallback(
    async ({ currentPosts }) => {
      try {
        setHasError(false);
        setIsLoadingMore(true);
        const resp = await BlogService.getPosts({
          postType: postType.name,
          category: 'all',
          page: currentPosts.meta.pagination.page + 1,
          excludedId: highlightedPost?.id,
          limit: pageLimit,
        });
        setIsLoadingMore(false);
        setPosts({ ...resp, data: currentPosts.data.concat(resp.data) });
      } catch {
        setHasError(true);
      }
    },
    [pageLimit, highlightedPost, postType],
  );

  if (!content) return null;

  const { header, seo } = content;
  const { subscribe, cta } = shared;

  return (
    <Layout bannerInfo={bannerInfo}>
      <BreadcrumbJsonLd
        useAppDir={true}
        itemListElements={[
          {
            position: 1,
            name: 'Resources',
            item: `${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/resources`,
          },
          {
            position: 2,
            name: seo.title,
            item: `${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/${postType.url}`,
          },
        ]}
      />
      <PageWrapper>
        <Container>
          <ContentWrapper>
            <Title size="2xl">{header?.title}</Title>
            <Subtitle>{header?.subtitle}</Subtitle>
            {hasShape && (
              <ListGraphicElements
                outlinedStyle={{ top: '30px', right: '94px' }}
                filledStyle={{ top: '79px', right: '16px' }}
              />
            )}
          </ContentWrapper>
        </Container>

        <HighlightedPost
          post={highlightedPost}
          postType={postType}
          hasReadingTime={hasReadingTime}
        />
        {posts?.data?.length > 0 && (
          <PostsWrapper>
            <PostsList
              posts={posts?.data}
              meta={posts?.meta}
              loadingMore={isLoadingMore}
              loadMore={() => fetchData({ currentPosts: posts })}
              hasError={hasError}
              postType={postType}
              hasReadingTime={hasReadingTime}
              sliderInMobile={false}
              list
              resources
            />
          </PostsWrapper>
        )}
        <CustomContainer>
          <SubscriberAll hasMore={hasMore} label={subscribe} />
        </CustomContainer>
      </PageWrapper>

      {cta && <CTA {...cta} />}
    </Layout>
  );
};

export default ResourcesBlog;
