import { Container } from '@/components/core/flexbox';
import styled, { css } from 'styled-components';

export const StyledContainer = styled(Container)`
  display: grid;
  gap: 24px;
  justify-items: start;
  position: relative;

  ${(props) =>
    props.$align === 'center' &&
    `
    justify-items: center;
    text-align: center;
  `}

  ${(props) =>
    props.$alignTablet === 'center' &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        justify-items: center;
        text-align: center;
      }
    `}

    ${(props) =>
    props.$alignTablet === 'left' &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        justify-items: start;
        text-align: left;
      }
    `}

  ${(props) =>
    props.$alignMobile === 'center' &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        justify-items: center;
        text-align: center;
      }
    `}

  ${(props) =>
    props.$alignMobile === 'left' &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        justify-items: start;
        text-align: left;
      }
    `}

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 16px;
  }

  .title,
  .label,
  .description {
    padding-bottom: 0;

    ${(props) =>
      props.$size === 'xs' &&
      `
      max-width: 500px;
    `}

    ${(props) =>
      props.$size === 'sm' &&
      `
      max-width: 640px;
    `}


    ${(props) =>
      props.$size === 'lg' &&
      `
      max-width: 830px;
    `}


    ${(props) =>
      props.$size === 'xl' &&
      `
      max-width: 100%;
    `}
  }

  .title {
    sup,
    sub {
      font-weight: var(--ui-font-weight-medium);
      font-size: 50%;
    }
  }
`;
