import { PostsList, POST_TYPES } from '@/components/shared/Blog';
import Intro from '@/components/shared/Intro';

import { CustomSection } from './styles';

export const variants = Object.freeze({ PRIMARY: 'primary', SECONDARY: 'secondary' });

export default function NewsList({ content, news, variant }) {
  const ctaProps = {
    label: content?.textLink?.label || 'See all resources',
    href: content?.textLink?.href || '/newsroom',
  };
  if (!content || !news || !news.length) return null;

  const { upperLabel, title } = content;

  return (
    <CustomSection $variant={variant}>
      <Intro
        upperLabel={upperLabel}
        title={title}
        button={{
          ...ctaProps,
          type: 'text',
        }}
        align="left"
        as="header"
        xl="0"
        md="0"
        sm="0"
        x="0"
      />

      <PostsList
        posts={news}
        hideWhenEmpty
        postWithBackground
        postType={POST_TYPES.BLOG_POST}
        className="news-post"
        hasReadingTime
      />
    </CustomSection>
  );
}
