import styled from 'styled-components';

const Progress = styled.div.attrs((props) => ({
  style: {
    background: `linear-gradient(to right, ${props.theme.colors.primary.default} ${props.scroll}%, transparent 0)`,
  },
}))`
  position: fixed;
  top: 80px;
  width: 100%;
  height: 3px;
  z-index: 3;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    top: 60px;
  }
`;

export default Progress;
