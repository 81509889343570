import styled from 'styled-components';
import { Col, Row } from '@/components/core/flexbox';
import { PressRelatedPosts } from '@/components/press';
import { SinglePost as SinglePostComponent } from '../posts/SinglePost';
import POST_TYPES from '../post-types';
import NextPostDesktop from './NextPostDesktop';
import RecommendedPost from './RecommendedPost';
import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import { Title } from '@/components/core';

const Wrapper = styled(Row)`
  margin-top: 16px;
  margin-bottom: 120px;
  display: flex;
  & ${Col}:first-child {
    padding-right: 16px;
  }
  & ${Col}:last-child {
    padding-left: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 0;
    margin-bottom: 80px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 64px;
    & ${Col}:first-child {
      padding: 0;
    }
    & ${Col}:last-child {
      padding: 0;
      margin-top: 24px;
    }
  }
`;

export const SectionTitle = styled(Title).attrs({ size: 'lg' })`
  margin-bottom: 12px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey.dark}1a;
  }
`;

const SinglePost = styled(SinglePostComponent)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0;
  }
`;

const RelatedPosts = ({ content, limit = 2, postType, hasReadingTime }) => {
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_xl}px)`);
  const isPress = postType === POST_TYPES.PRESS_PAGE;

  if (isPress) return <PressRelatedPosts content={content} postType={postType} />;
  return (
    <Wrapper>
      <Col xs={12} md={6} xl={7}>
        <SectionTitle>Up next</SectionTitle>
        {isDesktop ? (
          <NextPostDesktop post={content[0]} postType={postType} hasReadingTime={hasReadingTime} />
        ) : (
          <SinglePost
            post={content[0]}
            key={content[0]?.id}
            noDescription
            postType={postType}
            hasReadingTime={hasReadingTime}
          />
        )}
      </Col>
      <Col xs={12} md={6} xl={5}>
        <SectionTitle>Recommended</SectionTitle>
        {isDesktop ? (
          content
            .slice(0, limit)
            .map((post) => (
              <RecommendedPost
                key={post?.id}
                post={post}
                postType={postType}
                hasReadingTime={hasReadingTime}
              />
            ))
        ) : (
          <SinglePost
            key={content[1]?.id}
            post={content[1]}
            noDescription
            postType={postType}
            hasReadingTime={hasReadingTime}
          />
        )}
      </Col>
    </Wrapper>
  );
};

export default RelatedPosts;
