import styled, { css } from 'styled-components';
import { Section } from '@/components/core';

import { LinkButton } from '@/components/core/Button';
import theme from '@/utils/styles-variables';
import { variants } from './index';

function getStyles(variant) {
  switch (variant) {
    case variants.SECONDARY:
      return css`
        background-color: ${theme.colors.neutral.default};

        .news-post a {
          div + div {
            background-color: ${theme.colors.white};
          }
          @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
            border-radius: 8px;
            border: 2px solid ${theme.colors.white};
          }
        }
      `;

    default:
      return null;
  }
}

export const SeeAllButton = styled(LinkButton)`
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    margin-bottom: 14px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 10px;
  }
`;

export const CustomSection = styled(Section)`
  ${({ $variant }) => getStyles($variant)}
`;
