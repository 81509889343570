import styled from 'styled-components';
import { SinglePost } from '@/components/shared/Blog';
import { SectionTitle } from '@/components/shared/Blog/RelatedPosts/RelatedPosts';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 24px 0 136px;
  flex-wrap: wrap;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 0 0 50px;
    margin-top: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    padding: 10px 0 40px;
    margin-top: 0;
  }
`;

const Col = styled.div`
  display: flex;
  flex: 1;
  max-width: 33%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    min-width: calc(50% - 12px);
    div {
      margin: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
  }
`;

const PressRelatedPosts = ({ content, postType }) => {
  return (
    <>
      <SectionTitle>Related</SectionTitle>
      <Row>
        {content.map((post) => (
          <Col key={post.id}>
            <SinglePost press post={post} postType={postType} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PressRelatedPosts;
