import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import ReadingTime from '@/components/ReadingTime';
import getImgSrcWorkaround from '@/utils/images';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

const RecommendedPost = ({ className, post, postType, hasReadingTime }) => {
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_xl}px)`);

  if (!post && !post.attributes) return null;

  const { title, publishedOn, publishedAt, featuredImage, content, slug, wordCount } = post;
  const category = post?.category?.displayName;

  return (
    <Link href={`/${postType.url}/${slug}`}>
      <Post className={className}>
        <ImageWrapper>
          {featuredImage && (
            <Image
              src={getImgSrcWorkaround(featuredImage.url)}
              alt={featuredImage.alternativeText || ''}
              fill
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, 191px"
            />
          )}
        </ImageWrapper>
        <Content>
          <Title>{title}</Title>
          <DateWrapper>
            {!isDesktop && category && <Category>{category}</Category>}
            <DateInfo>
              <DateLabel>
                {new Date(publishedOn || publishedAt).toLocaleDateString('en-US', {
                  dateStyle: 'long',
                })}
              </DateLabel>
            </DateInfo>
            {hasReadingTime && (
              <DateInfo>
                <ReadingTime content={content} hasReadingTime wordCount={wordCount} />
              </DateInfo>
            )}
          </DateWrapper>
        </Content>
      </Post>
    </Link>
  );
};

const Post = styled.div`
  margin: 20px 0;
  display: inline-flex;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    margin-top: 12px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-basis: 100%;
    max-width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
  }
`;

const ImageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.secondary.light};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 112px;
  width: 191px;

  img {
    object-fit: cover;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 184px;
    width: 100%;
    margin-bottom: 24px;
  }
`;

const Content = styled.div`
  box-sizing: border-box;

  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    flex: 1;
    padding-left: 24px;
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 24px 0 16px 0;
  display: -webkit-box;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 16px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 16px;
    margin: 16px 0;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: ${(props) => props.theme.font.family.heading};
`;

const Category = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  opacity: 1;
`;

const DateInfo = styled.div`
  font-size: 14px;
  &:not(:first-child) {
    padding-left: 22px;
    position: relative;
    &:before {
      position: absolute;
      opacity: 0.3;
      content: '';
      top: 10px;
      left: 8px;
      height: 6px;
      width: 6px;
      background-color: ${(props) => props.theme.colors.grey.dark};
      border-radius: 50%;
    }
  }
`;

const DateLabel = styled.div`
  opacity: 0.7;
`;

export default RecommendedPost;
