import { memo } from 'react';
import { Paragraph } from '@/components/core';

const Content = ({ children }) => (
  <Paragraph
    size="lg"
    highlightLinks
    markdownProps={{
      elements: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'ul',
        'ol',
        'li',
        'blockquote',
        'p',
        'img',
        'a',
        'u',
        'del',
        'em',
        'iframe',
        'video',
        'source',
      ],
      components: {
        h1: 'h2',
      },
    }}
  >
    {children}
  </Paragraph>
);

export default memo(Content);
