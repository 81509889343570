import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
`;

export const InnerWrapper = styled.div`
  padding: 120px 0;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid rgba(31, 34, 44, 0.1);
  color: ${(props) => props.theme.colors.grey.dark};
  font-family: ${(props) => props.theme.font.family.heading};
  p {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.primary.default};
  }
  h4 {
    font-weight: 700;
    padding: 24px 0 8px;
  }
  a {
    font-weight: 400;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;
