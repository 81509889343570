'use client';

import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import Content from '@/components/shared/Blog/Content';
import DateComponent from '@/components/shared/Blog/DateComponent';
import ImageWrapper from '@/components/shared/Blog/ImageWrapper';
import { Title } from '@/components/core';
import { DetailsGraphicElements, Subscriber } from '@/components/shared/Blog';
import Progress from '@/components/core/Progress';
import CTA from '@/components/core/CTA';
import Social from '@/components/shared/Blog/Social';
import RelatedPosts from '@/components/shared/Blog/RelatedPosts';
import { Col, Container, Row } from '@/components/core/flexbox';
import { Button } from '@/components/core/Button';

import getImgSrcWorkaround from '@/utils/images';
import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

const ContentWrapper = styled.article`
  display: grid;
  position: relative;
  max-width: 834px;
  margin: 160px auto auto auto;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 108px;
  }

  .postTitle {
    margin-bottom: 32px;
  }
`;

const CustomImage = styled(Image)`
  max-width: 100%;
  object-fit: cover;
  height: auto;
  aspect-ratio: 834/416;
`;

const ButtonWrapper = styled.div`
  margin: 24px auto 0;
`;

const PostDetails = ({
  cta,
  post,
  slug,
  shared,
  related,
  postType,
  hasReadingTime,
  relatedCount,
}) => {
  const ref = useRef();
  const requestAnimationRef = useRef();
  const [progress, setProgress] = useState(0);
  const [scroll, setScroll] = useState(false);

  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_xl}px)`);

  const { cta: globalCTA, subscribe } = shared;
  const { title, content, date, publishedOn, publishedAt, featuredImage } = post;
  const publishedDate = date || publishedOn || publishedAt;
  const category = post.category?.displayName;

  const [reference, inView] = useInView({
    threshold: 0,
    initialInView: true,
  });

  useEffect(() => {
    const calculateProgress = () => {
      if (!ref.current) {
        return;
      }

      const newValue =
        (window.scrollY / (ref.current.getBoundingClientRect().height - window.innerHeight / 2)) *
        100;

      setProgress(newValue < 100 ? newValue : 100);

      requestAnimationRef.current = requestAnimationFrame(calculateProgress);
    };

    requestAnimationRef.current = requestAnimationFrame(calculateProgress);

    return () => cancelAnimationFrame(requestAnimationRef.current);
  }, []);

  useEffect(() => {
    setScroll(inView);
  }, [inView]);

  return (
    <>
      <Progress scroll={progress} />
      <Container>
        <Row>
          <Col xs={12}>
            <ContentWrapper ref={ref}>
              <Title as="h1" size="2xl" className="postTitle">
                {title}
              </Title>
              {isDesktop && <DetailsGraphicElements />}
              <DateComponent
                category={category}
                content={content}
                publishedOn={publishedDate}
                details
                hasReadingTime={hasReadingTime}
              />
              {isDesktop && <Social scroll={scroll} slug={slug} />}
              <ImageWrapper>
                {featuredImage && (
                  <CustomImage
                    src={getImgSrcWorkaround(featuredImage.url)}
                    width={834}
                    height={416}
                    alt={featuredImage.alternativeText || ''}
                    sizes="(max-width: 768px) 100vw, (max-width: 991px) 100vw, 834px"
                    priority
                  />
                )}
              </ImageWrapper>
              <Content>{content}</Content>
              {cta?.url && (
                <ButtonWrapper>
                  <Button href={cta.url} onClick={cta?.onClick}>
                    {cta.label}
                  </Button>
                </ButtonWrapper>
              )}
            </ContentWrapper>
          </Col>
        </Row>
      </Container>
      <div ref={reference}>
        <Social big slug={slug} />
        <Subscriber label={subscribe} />
        <Container>
          {related?.length > 0 && (
            <RelatedPosts
              content={related}
              postType={postType}
              hasReadingTime={hasReadingTime}
              limit={relatedCount}
            />
          )}
        </Container>
        <CTA forwardRef={reference} {...globalCTA} />
      </div>
    </>
  );
};

export default PostDetails;
