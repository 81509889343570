import { memo } from 'react';
import { StyledContainer } from './styles';
import { Badge, Paragraph, Section, Title, UpperText } from '@/components/core';
import dynamic from 'next/dynamic';
import useButtonTracking from '@/utils/useButtonTracking';

import MarkdownRichText from '@/components/shared/MarkdownRichText';

const Button = dynamic(() => import('@/components/core/Button/Button'));
const LinkButton = dynamic(() => import('@/components/core/Button/LinkButton'));

function Intro({
  id,
  align = 'center',
  alignTablet,
  alignMobile,
  button,
  title,
  description,
  upperLabel,
  upperLabelVariant = 'default',
  textSize = 'md',
  titleAs = 'h2',
  titleSize = '2xl',
  titleVariant = 'default',
  size = 'lg',
  innerSpacer = false,
  ...rest
}) {
  const ButtonComponent = button?.type === 'text' ? LinkButton : Button;
  const isBadgeDark = upperLabelVariant === 'badgeDark';
  const isBadge = upperLabelVariant === 'badge' || isBadgeDark;
  const UpperLabelComponent = isBadge ? Badge : UpperText;
  const trackButtonClicked = useButtonTracking({
    label: button?.label,
    location: upperLabel,
    url: button?.href,
  });

  const { label: buttonLabel, ...buttonProps } = button || {};

  return (
    <Section id={id ? `intro-${id}` : undefined} innerSpacer={innerSpacer} {...rest}>
      <StyledContainer
        $align={align}
        $alignTablet={alignTablet}
        $alignMobile={alignMobile}
        $size={size}
        className="container"
      >
        {upperLabel && (
          <UpperLabelComponent
            data-testid="upperLabel"
            className={isBadge ? 'badge' : 'label'}
            variant={isBadgeDark ? 'dark' : 'default'}
          >
            {upperLabel}
          </UpperLabelComponent>
        )}
        <Title className="title" size={titleSize} as={titleAs} variant={titleVariant}>
          <MarkdownRichText elements={['a']}>{title}</MarkdownRichText>
        </Title>
        {description && (
          <Paragraph
            className="description"
            size={textSize}
            markdownProps={{ elements: ['a', 'p'] }}
          >
            {description}
          </Paragraph>
        )}
        {buttonLabel && (
          <ButtonComponent
            {...buttonProps}
            onClick={() => {
              trackButtonClicked();

              if (buttonProps.onClick) {
                buttonProps.onClick();
              }
            }}
            $primary
          >
            {buttonLabel}
          </ButtonComponent>
        )}
      </StyledContainer>
    </Section>
  );
}

export default memo(Intro);
