import Link from 'next/link';
import styled, { css } from 'styled-components';
import FacebookLogo from '@/public/assets/icons/facebook.svg';
import TwitterLogo from '@/public/assets/icons/twitter.svg';
import LinkedInLogo from '@/public/assets/icons/linkedin.svg';

const Social = ({ big, scroll, slug }) => {
  const URL = `${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/newsroom/${slug}`;

  return (
    <>
      {big && <Title>SHARE THIS ARTICLE</Title>}
      <BigWrapper scroll={scroll} big={big}>
        <Wrapper big={big}>
          <Link
            href={`https://www.facebook.com/sharer/sharer.php?u=${URL}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoWrapper>
              <FacebookLogo />
            </LogoWrapper>
          </Link>
          <Link
            href={`https://twitter.com/intent/tweet?u=${URL}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoWrapper>
              <TwitterLogo />
            </LogoWrapper>
          </Link>
          <Link
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${URL}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoWrapper>
              <LinkedInLogo />
            </LogoWrapper>
          </Link>
        </Wrapper>
      </BigWrapper>
    </>
  );
};

const BigWrapper = styled.div`
  position: absolute;
  right: -30px;
  opacity: 0;
  animation: fade-in 500ms forwards;
  z-index: 11;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${(props) =>
    props.big &&
    css`
      opacity: 1;
      position: relative;
      right: unset;
    `}

  ${(props) =>
    props.scroll &&
    css`
      opacity: 1;
      pointer-events: none;
      animation: fade-out 500ms forwards;
    `}

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const Title = styled.div`
  margin-top: 48px;
  margin-bottom: 24px;
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 2px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    margin-top: 64px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-top: 80px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;

  & > a + a {
    margin-top: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 0;
  }

  ${(props) =>
    props.big &&
    css`
      position: relative;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      width: 100%;
      justify-content: center;

      & > a + a {
        margin-top: 0;
      }
    `}
`;

const LogoWrapper = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.grey.light};
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition:
    background-color 300ms ease-in-out,
    border-color 300ms ease-in-out;

  svg {
    path {
      transition: fill 300ms ease-in-out;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.grey.dark};
    border-color: ${(props) => props.theme.colors.grey.dark};

    svg {
      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

export default Social;
