import styled from 'styled-components';

const ImageWrapper = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    margin-bottom: 48px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-bottom: 54px;
  }
`;

export default ImageWrapper;
